import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
import { db, firebase } from '../../firebase/Firebase'
import ColType from '../.././Types'
//import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
//import PacingGuideSC from './PacingGuideSC'
import { Layout, Select, Icon, Tabs, Button, notification, Collapse, Popconfirm, Checkbox } from 'antd'
//import PacingGuideAssessment from './PacingGuideAssessment'
//import ComponentFactory from '../edviz/ComponentFactory'
import { getQueryStringParam, flattenDoc, cleverStartQuiz, getQuarterAndWeekFromNum, shuffleArray, manualCheckAssessment } from '../../Util'
import { Link } from 'react-router-dom'
import DocumentDelegator from '../../edviz/EdvizDelegator'
import PacingGuideUploads from './PacingGuideUploads'
import {
    graphicOrganizerEmpty_ela_3_aor_5_1,
    graphicOrganizerFilled_ela_3_aor_5_1,
    graphicOrganizerPrintable_ela_3_aor_5_1,
    studentResource_ela_3_aor_5_1,
    teacherResource_ela_3_aor_5_1,
} from '../html/resources/SC/ELA/3rd Grade/ELA.3.AOR.5.1/resource.js'
import {
    graphicOrganizerEmpty_ela_3_aor_6_1_a,
    graphicOrganizerFilled_ela_3_aor_6_1_a,
    graphicOrganizerPrintable_ela_3_aor_6_1_a,
    studentResource_ela_3_aor_6_1_a,
    teacherResource_ela_3_aor_6_1_a,
} from '../html/resources/SC/ELA/3rd Grade/ELA.3.AOR.6.1.a/resource.js'
import {
    graphicOrganizerEmpty_ela_3_aor_8_1_a,
    graphicOrganizerFilled_ela_3_aor_8_1_a,
    graphicOrganizerPrintable_ela_3_aor_8_1_a,
    studentResource_ela_3_aor_8_1_a,
    teacherResource_ela_3_aor_8_1_a,
} from '../html/resources/SC/ELA/3rd Grade/ELA.3.AOR.8.1.a/resource.js'

import {
    graphicOrganizerEmpty_ela_k_f_3_8_c,
    graphicOrganizerFilled_ela_k_f_3_8_c,
    graphicOrganizerPrintable_ela_k_f_3_8_c,
    studentResource_ela_k_f_3_8_c,
    teacherResource_ela_k_f_3_8_c,
} from '../html/resources/SC/ELA/Kindergarten/ELA.K.F.3.8.c/resource.js'

import {
    graphicOrganizerEmpty_ela_1_f_3_4_a,
    graphicOrganizerFilled_ela_1_f_3_4_a,
    graphicOrganizerPrintable_ela_1_f_3_4_a,
    studentResource_ela_1_f_3_4_a,
    teacherResource_ela_1_f_3_4_a,
} from '../html/resources/SC/ELA/1st Grade/ELA.1.F.3.4.a/resource.js'

import {
    graphicOrganizerEmpty_ela_1_f_3_7,
    graphicOrganizerFilled_ela_1_f_3_7,
    graphicOrganizerPrintable_ela_1_f_3_7,
    studentResource_ela_1_f_3_7,
    teacherResource_ela_1_f_3_7,
} from '../html/resources/SC/ELA/1st Grade/ELA.1.F.3.7/resource.js'

import {
    graphicOrganizerEmpty_ela_2_f_3_7,
    graphicOrganizerFilled_ela_2_f_3_7,
    graphicOrganizerPrintable_ela_2_f_3_7,
    studentResource_ela_2_f_3_7,
    teacherResource_ela_2_f_3_7,
} from '../html/resources/SC/ELA/2nd Grade/ELA.2.F.3.7/resource.js'

import {
    graphicOrganizerEmpty_ela_2_f_3_8_g,
    graphicOrganizerFilled_ela_2_f_3_8_g,
    graphicOrganizerPrintable_ela_2_f_3_8_g,
    studentResource_ela_2_f_3_8_g,
    teacherResource_ela_2_f_3_8_g,
} from '../html/resources/SC/ELA/2nd Grade/ELA.2.F.3.8.g/resource.js'

import {
    graphicOrganizerEmpty_math_8_8_F_4_d,
    graphicOrganizerFilled_math_8_8_F_4_d,
    graphicOrganizerPrintable_math_8_8_F_4_d,
    studentResource_math_8_8_F_4_d,
    teacherResource_math_8_8_F_4_d,
} from '../html/resources/SC/Math/8th Grade/8.F.4.d/resource.js'

import {
    graphicOrganizerEmpty_math_algebra_1_A1_FLQE_2_,
    graphicOrganizerFilled_math_algebra_1_A1_FLQE_2_,
    graphicOrganizerPrintable_math_algebra_1_A1_FLQE_2_,
    studentResource_math_algebra_1_A1_FLQE_2_,
    teacherResource_math_algebra_1_A1_FLQE_2_,
} from '../html/resources/SC/Math/Algebra 1/A1.FLQE.2*/resource.js'

import {
    graphicOrganizerEmpty_ELA_english_1_ELA_E1_AOR_1_1,
    graphicOrganizerFilled_ELA_english_1_ELA_E1_AOR_1_1,
    graphicOrganizerPrintable_ELA_english_1_ELA_E1_AOR_1_1,
    studentResource_ELA_english_1_ELA_E1_AOR_1_1,
    teacherResource_ELA_english_1_ELA_E1_AOR_1_1,
} from '../html/resources/SC/ELA/English 1/ELA.E1.AOR.1.1/resource.js'

//import PacingGuideFeedback from './PacingGuideFeedback'
import PacingGuideAssessmentModalForm from './PacingGuideAssessmentModalForm'
import HTMLAssessmentViewer from '../html/components/HTMLAssessmentViewer.js'
//import LessonPlanPDFDownload from './LessonPlanPDF'
const { Content } = Layout

class PacingGuideLessonPlanV3 extends Component {
    state = {
        lessonPlan: null,
        lessonPlanFound: true,
        fileURLDict: {},
        lessonPlanStandardNames: ['ELA.1.F.3.6', 'ELA.3.AOR.6.1.a',
            'ELA.6.AOR.1.2', 'ELA.C.1', 'ELA.K.F.1.4', '2.ATO.1', '3.ATO.1',
            '4.ATO.4', '7.RP.2.d', 'A1.AAPR.1', 'K.NS.1', '3-ESS2-1',
            'B-LS1-5', 'USG.1.IP'],
        subjects: ['ELA', 'Math', 'Social Studies', 'Science'],
        lessonPlanStandardNamesSubjectDict: {
            'ELA': ['ELA.K.F.1.4', 'ELA.1.F.3.6', 'ELA.3.AOR.6.1.a',
                'ELA.6.AOR.1.2', 'ELA.C.1'],
            'Math': ['K.NS.1', '2.ATO.1', '3.ATO.1',
                '4.ATO.4', '7.RP.2.d', 'A1.AAPR.1'],
            'Social Studies': ['USG.1.IP'],
            'Science': ['3-ESS2-1', 'B-LS1-5'],
        },
        standardName: '',
        standard: '',
        grade: '',
        category: '',
        startingQuiz: false,
        assessments: [],
        selectWeek: '',
        selectIdx: '',
        selectStandard: '',
        fromWeeklyPlanner: false,
        disabledQuizzesDict: {},
        reviewTheseQuizzesDict: {},
        quizzes: [],
        notLiveQuizzes: [],
        quizzesForStandardCategoryAndStandardName: [],
    }

    componentDidMount() {
        let standardName = getQueryStringParam('standardName')
        let standard = getQueryStringParam('standard')
        let grade = getQueryStringParam('grade')
        let category = getQueryStringParam('category')
        let quarter = getQueryStringParam('quarter')
        let week = getQueryStringParam('week')
        let standardIndex = getQueryStringParam('index')
        let fromWeeklyPlanner = getQueryStringParam('weeklyPlanner')
        this.setState({
            selectWeek: week,
            selectIdx: standardIndex,
            selectStandard: standard,
            fromWeeklyPlanner: fromWeeklyPlanner,
        })
        this.getData(standardName, standard, grade, category)

        if (this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.setStandardNameAndCategoryFunc &&
            (this.props.pacingGuideBaseProps.standardName !== standardName ||
                this.props.pacingGuideBaseProps.standardCategory !== category)) {
            if (this.props.pacingGuideBaseProps.mounted) {
                this.props.pacingGuideBaseProps.setStandardNameAndCategoryFunc(
                    standardName, category
                )
            } else {
                // wait for base component to mount before calling func
                let baseMountedInterval = setInterval(() => {
                    if (this.props.pacingGuideBaseProps.mounted &&
                        this.props.pacingGuideBaseProps &&
                        this.props.pacingGuideBaseProps.hasOwnProperty('state') &&
                        this.props.pacingGuideBaseProps.state) {
                        this.props.pacingGuideBaseProps.setStandardNameAndCategoryFunc(
                            standardName, category
                        )
                        this.getData(standardName, standard, grade, category)
                        clearInterval(baseMountedInterval)
                    }
                }, 100);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        /*let standardName = getQueryStringParam('standardName')
        let standard = getQueryStringParam('standard')
        let grade = getQueryStringParam('grade')
        let category = getQueryStringParam('category')
        this.getData(standardName, standard, grade, category)*/
    }

    getData = (standardName, standard, grade, category) => {
        if (!(this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.hasOwnProperty('state') &&
            this.props.pacingGuideBaseProps.state &&
            standardName &&
            grade &&
            category)) {
            return
        }

        // already have this data
        if (standardName === this.state.standardName &&
            standard === this.state.standard &&
            grade === this.state.grade &&
            category === this.state.category) {
            return
        }

        //console.log('getData')

        this.setState({
            standardName: standardName,
            standard: standard,
            selectStandard: standard,
            grade: grade,
            category: category,
        })

        let state = this.props.pacingGuideBaseProps.state
        // demo is NY, fix later
        if (state === 'NY') {
            state = 'SC'
        }

        // KY ELA standards are 9-10 and 11-12
        if (state === 'KY' && category === 'ELA') {
            if (standardName === '10th Grade') {
                standardName = '9th Grade'
            } else if (standardName === '12th Grade') {
                standardName = '11th Grade'
            }
        }

        db.collection(ColType.lessonPlans)
            .where('state', '==', state)
            .where('standard.standardName', '==', standardName)
            .where('standard.standardCategory', '==', category)
            .where('standard.standard', '==', standard)
            .where('version', '==', '1.1')
            .limit(1)
            .get()
            .then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0]

                    let lessonPlan = flattenDoc(doc)
                    //console.log(lessonPlan)
                    lessonPlan.doc = JSON.parse(lessonPlan.doc)

                    //this.getFileURLDict(lessonPlan)
                    // try to parse out DOK Levels
                    let dokLevelDocs = []
                    let lessonPlanDocs = []
                    let mode = 'lessonPlan'
                    lessonPlan.doc.components.map((doc) => {
                        let heading = '';
                        if ((doc.hasOwnProperty('content') &&
                            (doc.content === 'DOK Levels' || doc.content === 'DOK Differentiation')) ||
                            (doc.hasOwnProperty('heading') &&
                                (doc.heading === 'DOK Levels' || doc.heading === 'DOK Differentiation'))) {
                            mode = 'dokLevels'
                            // edit to make it an h1
                            doc.type = 'H1'
                            doc.heading = 'DOK Differentiation'
                            doc.content = 'DOK Differentiation'
                        } else if (mode === 'dokLevels' &&
                            ((doc.hasOwnProperty('content') &&
                                doc.content === 'Assessment') ||
                                (doc.hasOwnProperty('heading') &&
                                    doc.heading === 'Assessment'))) {
                            mode = 'lessonPlan'
                        }

                        if (mode === 'dokLevels' && doc.type === 'H3') {
                            doc.type = 'H2'
                        }

                        if (mode === 'lessonPlan') {
                            lessonPlanDocs.push(doc)
                        } else {
                            dokLevelDocs.push(doc)
                        }
                        return false
                    })

                    /*
                    const lessonPlanHeadingDocs = [{
                        type: 'Image',
                        src: '/dotitlogo-grayscale.png',
                        height: 50
                    },
                    {
                        type: 'H1',
                        heading: 'Sample Lesson'
                    },
                    {
                        type: 'MarginBottom',
                    }];

                    const DOKHeadingDocs = [{
                        type: 'Image',
                        src: '/dotitlogo-grayscale.png',
                        width: 167,
                        height: 50
                    },
                    {
                        type: 'H1',
                        heading: 'DOK Differentiation'
                    },
                    {
                        type: 'MarginBottom',
                    }];

                    const standardPDFPrintDocs = [
                        {
                            type: 'H1',
                            heading: 'Standard',
                        },
                        {
                            type: 'P',
                            content: [
                                {
                                    content: 'Subject: ' + lessonPlan.standard.standardCategory
                                }
                            ]
                        },
                        {
                            type: 'P',
                            content: [
                                {
                                    type: 'Text',
                                    content: 'Category: ' + lessonPlan.standard.category
                                }
                            ]
                        },
                        {
                            type: 'P',
                            content: [
                                {
                                    type: 'Text',
                                    content: 'Grade: ' + lessonPlan.standard.grade
                                }
                            ]
                        },
                        {
                            type: 'P',
                            content: [
                                {
                                    type: 'Text',
                                    content: 'Standard: ' + lessonPlan.standard.standard
                                }
                            ]
                        },
                        {
                            type: 'P',
                            content: [
                                {
                                    type: 'Text',
                                    content: lessonPlan.standard.title
                                }
                            ]
                        },
                        {
                            type: 'MarginBottom',
                        }
                    ]*/


                    if (dokLevelDocs.length > 0) {
                        lessonPlan.dokLevelDocs = dokLevelDocs
                        lessonPlan.lessonPlanDocs = lessonPlanDocs
                        //lessonPlan.printDOKLevelDocs = DOKHeadingDocs.concat(standardPDFPrintDocs).concat(dokLevelDocs)
                        //lessonPlan.printLessonPlanDocs = lessonPlanHeadingDocs.concat(standardPDFPrintDocs).concat(lessonPlanDocs)
                    } else {
                        //lessonPlan.printLessonPlanDocs = lessonPlanHeadingDocs.concat(standardPDFPrintDocs).concat(lessonPlanDocs)
                    }

                    //console.log('lessonPlan', lessonPlan)

                    this.setState({
                        lessonPlan: lessonPlan,
                        lessonPlanFound: true,
                    })
                    //console.log('lessonPlan', lessonPlan)
                } else {
                    //console.log('lessonPlan doc does not exist')
                    this.setState({
                        lessonPlan: null,
                        lessonPlanFound: false,
                    })
                }
            })

        if (this.props.teacherBaseProps &&
            this.props.teacherBaseProps.teacher &&
            (this.props.teacherBaseProps.teacher.id === '64e638af44f2aa047457f924' ||
                this.props.teacherBaseProps.teacher.id === '64c9bd88ee3bd703dc3f33ca')
        ) {
            // breakdown
            let hasNoPassageDict = {}
            let hasNoPassages = []
            db.collection(ColType.quizzes)
                .where('standardName', '==', standardName)
                .where('standardCategory', '==', category)
                .where('assessmentCategory', '==', 'quiz')
                .where('state', '==', state)
                .where('year', '==', '2024')
                .where('districtId', '==', '__all__')
                .where('live', '==', true)
                .get()
                .then((querySnapshot) => {
                    let quizzes = []
                    let quizzesThatFailedManualCheck = []
                    querySnapshot.forEach((doc) => {
                        let quiz = flattenDoc(doc)
                        quiz.docs = JSON.parse(quiz.docs)
                        for (let i = 0; i < quiz.docs.length; i++) {
                            let doc = quiz.docs[i]
                            let hasPassage = false;
                            for (let j = 0; j < doc.components[0].question.length; j++) {
                                let question = doc.components[0].question[j]
                                if (question.hasOwnProperty('type') && question.type === 'Passage') {
                                    hasPassage = true
                                    break
                                }
                            }
                            if (!hasPassage) {
                                if (!hasNoPassageDict.hasOwnProperty(quiz.standards[0])) {
                                    //console.log('Quiz does not have passage', quiz.standards[0])
                                    hasNoPassageDict[quiz.standards[0]] = true;
                                    hasNoPassages.push(quiz.standards[0])
                                    //console.log('hasNoPassages', hasNoPassages)
                                }
                                break
                            }
                        }
                        // check before shuffling
                        if (!manualCheckAssessment(quiz, true)) {
                            console.log('Quiz failed manual check', quiz);
                            let quizStandard = ''
                            if (quiz.standards && quiz.standards.length > 0) {
                                quizStandard = quiz.standards[0]
                            }
                            if (quizzesThatFailedManualCheck.indexOf(quizStandard) === -1) {
                                quizzesThatFailedManualCheck.push(quizStandard)
                            }
                        }
                        quizzes.push(quiz)
                    });
                    quizzesThatFailedManualCheck.sort()
                    quizzes.sort((a, b) => {
                        return new Date(a.createdAt) - new Date(b.createdAt)
                    })
                    let standardQuizzes = {};
                    quizzes.map((quiz) => {
                        const standard = quiz.standards[0]
                        if (!standardQuizzes.hasOwnProperty(standard)) {
                            standardQuizzes[standard] = []
                        }
                        standardQuizzes[standard].push(quiz)
                    })

                    let standardsWithoutQuizzes = []
                    for (let i = 0; i < this.props.pacingGuideBaseProps.standards.length; i++) {
                        let standard = this.props.pacingGuideBaseProps.standards[i]
                        if (!standardQuizzes.hasOwnProperty(standard.standard) &&
                            standardsWithoutQuizzes.indexOf(standard.standard) === -1) {
                            standardsWithoutQuizzes.push(standard.standard);
                        }
                    }

                    console.log(standardName, 'quizzes', querySnapshot.docs.length);
                    console.log('quizzesThatFailedManualCheck', quizzesThatFailedManualCheck);
                    console.log('standardsWithQuizzes', standardQuizzes)
                    console.log('standardsWithoutQuizzes', standardsWithoutQuizzes)
                    console.log(Object.keys(standardQuizzes).length + '/' +
                        (Object.keys(standardQuizzes).length + standardsWithoutQuizzes.length) +
                        ' standards have quizzes')

                    console.log('quizzes', quizzes)
                    //this.setState({
                    //    quizzesForStandardCategoryAndStandardName: quizzes,
                    //});
                })

            db.collection(ColType.quizzes)
                .where('standardName', '==', standardName)
                .where('standardCategory', '==', category)
                .where('standards', 'array-contains', standard)
                .where('assessmentCategory', '==', 'quiz')
                .where('state', '==', state)
                .where('year', '==', '2024')
                .where('districtId', '==', '__all__')
                .where('live', '==', false)
                //.where('type', '==', 'quiz')
                //.orderBy('createdAt', 'desc')
                .get()
                .then((querySnapshot) => {
                    let quizzes = []
                    querySnapshot.forEach((doc) => {
                        let quiz = flattenDoc(doc)
                        //console.log('(Quiz) quiz', quiz)
                        quiz.docs = JSON.parse(quiz.docs)
                        // shuffle questions
                        quiz.docs.map((doc) => {
                            try {
                                // will throw error here before shuffle, determining if format is correct
                                let answers = doc.components[0].answers
                                // format is known, shuffle
                                doc.components[0].answers = shuffleArray(doc.components[0].answers)
                            } catch (e) {
                                // pass
                                return false
                            }
                        })

                        quizzes.push(quiz)
                    })

                    this.setState({
                        notLiveQuizzes: quizzes,
                    })
                    console.log('(Not Live Quiz) quizzes', quizzes)
                })
        }

        db.collection(ColType.quizzes)
            .where('standardName', '==', standardName)
            .where('standardCategory', '==', category)
            .where('standards', 'array-contains', standard)
            .where('assessmentCategory', '==', 'quiz')
            .where('state', '==', state)
            .where('year', '==', '2024')
            .where('districtId', '==', '__all__')
            .where('live', '==', true)
            .orderBy("createdAt", "asc")
            .get()
            .then((querySnapshot) => {
                let quizzes = []
                querySnapshot.forEach((doc) => {
                    let quiz = flattenDoc(doc)
                    //console.log('(Quiz) quiz', quiz)
                    quiz.docs = JSON.parse(quiz.docs)
                    // shuffle questions
                    quiz.docs.map((doc) => {
                        try {
                            // will throw error here before shuffle, determining if format is correct
                            let answers = doc.components[0].answers
                            // format is known, shuffle
                            doc.components[0].answers = shuffleArray(doc.components[0].answers)
                        } catch (e) {
                            // pass
                            return false
                        }
                    })

                    quizzes.push(quiz)
                })

                this.setState({
                    quizzes: quizzes,
                })
                //console.log('(Quiz) quizzes', quizzes)
            })
    }

    downloadFile = (downloadURL) => {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest()
            xhr.responseType = 'blob'
            xhr.onload = (event) => {
                var blob = xhr.response
                //console.log('blob', blob)
                resolve(blob)
            }
            xhr.onerror = (event) => {
                console.error('error')
                reject()
            }
            xhr.open('GET', downloadURL)
            xhr.send()
        })
    }

    onLessonPlanStandardChange = (value) => {
        this.getData(this.state.standardName, value, this.state.grade, this.state.category)
    }

    onLessonPlanStandardChangeWeeks = (pValue) => {
        // split on ('--')
        let value = pValue.split('--')
        if (value.length !== 3) {
            return
        }
        const standardValue = value[1]
        if (!standardValue) {
            return
        }
        this.setState({
            selectWeek: value[0],
            selectStandard: standardValue,
            selectIdx: value[2],
        })
        this.getData(this.state.standardName, standardValue, this.state.grade, this.state.category)
    }

    getFileURLDict = (lessonPlan) => {
        this.setState({ fileURLDict: {} })
        /*let fileURLDict = {}

        var storage = firebase.app().storage("gs://lessonplanpdfs")

        let promises = []
        if (lessonPlan) {
            lessonPlan.docs.map((doc, idx) => {
                if (!(doc.hasOwnProperty('metadata') &&
                    doc.metadata &&
                    doc.metadata.hasOwnProperty('uuid') &&
                    doc.metadata.uuid)) {
                    return false
                }
                if (!(doc.hasOwnProperty('metadata') &&
                    doc.metadata &&
                    doc.metadata.hasOwnProperty('docType') &&
                    doc.metadata.docType)) {
                    return false
                }

                promises.push(new Promise((resolve, reject) => {
                    var fileRef = storage.ref().child(doc.metadata.uuid + '.pdf')

                    fileRef.getDownloadURL().then((url) => {
                        //console.log('url', url)
                        let key = doc.metadata.docType
                        if (doc.metadata.docType === 'resource' ||
                            doc.metadata.docType === 'assessment') {
                            key = doc.metadata.uuid
                        }
                        fileURLDict[key] = url
                        resolve()
                    }).catch((error) => {
                        //console.log('error', error)
                        reject(error)
                    })
                }))

                if (doc.metadata.docType === 'lesson_plan') {
                    promises.push(new Promise((resolve, reject) => {
                        var fileRef = storage.ref().child(doc.metadata.uuid + '_full.pdf')

                        fileRef.getDownloadURL().then((url) => {
                            //console.log('url', url)
                            fileURLDict['lesson_plan_full'] = url
                            resolve()
                        }).catch((error) => {
                            //console.log('error', error)
                            reject(error)
                        })
                    }))
                }

                return false
            })
        }

        Promise.all(promises)
            .then(() => {
                //console.log('fileURLDict', fileURLDict)
                this.setState({ fileURLDict: fileURLDict })
            })
            .catch((error) => {
                //console.log('error', error)
            })*/
    }

    startQuiz = (assessmentDocs) => {
        if (!this.props.teacherBaseProps.teacher) {
            //console.log('startQuiz - teacher is null')
            return
        }
        if (!(this.state.lessonPlan &&
            this.state.lessonPlan.standard &&
            this.state.lessonPlan.standard.standard &&
            this.props.pacingGuideBaseProps &&
            this.props.pacingGuideBaseProps.standardsDict &&
            this.props.pacingGuideBaseProps.standardsDict.hasOwnProperty(this.state.lessonPlan.standard.standard) &&
            this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard])) {
            //console.log('could not find pacing guide standard')
            return
        }

        let studentsWithCleverIds = []
        if (this.props.teacherBaseProps &&
            this.props.teacherBaseProps.students) {
            studentsWithCleverIds = this.props.teacherBaseProps.students.filter((student) => {
                return student.hasOwnProperty('cleverId') && student.cleverId
            })
        }

        if (studentsWithCleverIds.length === 0) {
            //console.log('startQuiz - no students with cleverIds')
            notification.open({
                type: 'error',
                duration: 4,
                message: 'You currently have no students from Clever. Please sync your students from Clever and try again.',
                placement: 'bottomRight'
            })
            return
        }

        this.setState({
            startingQuiz: true,
        })

        cleverStartQuiz(this.props.teacherBaseProps.teacher, studentsWithCleverIds, assessmentDocs,
            this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard])
            .then((result) => {
                if (result.hasOwnProperty('message') &&
                    result.message === 'Error during assessment creation') {
                    notification.open({
                        type: 'error',
                        duration: 4,
                        message: 'An error occurred while starting the quiz. Please try again.',
                        placement: 'bottomRight'
                    })
                } else {
                    notification.open({
                        type: 'success',
                        duration: 4,
                        message: 'Quiz started successfully',
                        placement: 'bottomRight'
                    })
                }

                this.setState({
                    startingQuiz: false,
                })
            })
            .catch(() => {
                notification.open({
                    type: 'error',
                    duration: 4,
                    message: 'An error occurred while starting the quiz. Please try again.',
                    placement: 'bottomRight'
                })

                this.setState({
                    startingQuiz: false,
                })
            })
    }

    deleteQuiz = (quiz) => {
        db.collection(ColType.quizzes)
            .doc(quiz.id)
            .delete()
            .then(() => {
                notification.open({
                    type: 'success',
                    duration: 4,
                    message: 'Quiz deleted successfully',
                    placement: 'bottomRight'
                })

                let quizzes = this.state.quizzes
                let idx = quizzes.indexOf(quiz)
                if (idx !== -1) {
                    quizzes.splice(idx, 1)
                    this.setState({
                        quizzes: quizzes,
                    })
                }

                let notLiveQuizzes = this.state.notLiveQuizzes
                idx = notLiveQuizzes.indexOf(quiz)
                if (idx !== -1) {
                    notLiveQuizzes.splice(idx, 1)
                    this.setState({
                        notLiveQuizzes: notLiveQuizzes,
                    })
                }
            })
            .catch(() => {
                notification.open({
                    type: 'error',
                    duration: 4,
                    message: 'An error occurred while deleting the quiz. Please try again.',
                    placement: 'bottomRight'
                })
            })
    }

    setLive = (quiz, live) => {
        db.collection(ColType.quizzes)
            .doc(quiz.id)
            .update({
                live: live,
            })
            .then(() => {
                notification.open({
                    type: 'success',
                    duration: 4,
                    message: 'Quiz updated successfully',
                    placement: 'bottomRight'
                })

                quiz.live = live
                if (live) {
                    let notLiveQuizzes = this.state.notLiveQuizzes
                    let idx = notLiveQuizzes.indexOf(quiz)
                    if (idx !== -1) {
                        notLiveQuizzes.splice(idx, 1)
                        this.setState({
                            notLiveQuizzes: notLiveQuizzes,
                        })
                    }

                    let quizzes = this.state.quizzes
                    idx = quizzes.indexOf(quiz)
                    if (idx === -1) {
                        quizzes.push(quiz)
                        this.setState({
                            quizzes: quizzes,
                        })
                    }
                } else {
                    let quizzes = this.state.quizzes
                    let idx = quizzes.indexOf(quiz)
                    if (idx !== -1) {
                        quizzes.splice(idx, 1)
                        this.setState({
                            quizzes: quizzes,
                        })
                    }

                    let notLiveQuizzes = this.state.notLiveQuizzes
                    idx = notLiveQuizzes.indexOf(quiz)
                    if (idx === -1) {
                        notLiveQuizzes.push(quiz)
                        this.setState({
                            notLiveQuizzes: notLiveQuizzes,
                        })
                    }
                }

            })
            .catch(() => {
                notification.open({
                    type: 'error',
                    duration: 4,
                    message: 'An error occurred while updating the quiz. Please try again.',
                    placement: 'bottomRight'
                })
            })
    }

    render() {
        const componentContainerClassName = 'question-container'
        const pdfButtonClassName = 'ant-btn ant-btn-primary lg-btn inline-flex mr-1'
        const linkBack = this.state.fromWeeklyPlanner ? 'weekly-planner' : 'guide'
        const linkBackText = this.state.fromWeeklyPlanner ? 'Weekly Planner' : 'Pacing Guide'
        let person = null
        if (this.props.teacherBaseProps) {
            if (this.props.teacherBaseProps.teacher) {
                person = this.props.teacherBaseProps.teacher
            } else if (this.props.teacherBaseProps.admin) {
                person = this.props.teacherBaseProps.admin
            } else if (this.props.teacherBaseProps.schoolAdmin) {
                person = this.props.teacherBaseProps.schoolAdmin
            } else if (this.props.teacherBaseProps.instructionalFacilitator) {
                person = this.props.teacherBaseProps.instructionalFacilitator
            }
        }

        return (
            <div>
                <Layout className="content layout-header-mt">
                    <Layout className="mt-0">
                        <Content className="mt-0 sub-menu-width-vlg m-lr-auto" style={{ minWidth: '1086px' }}>
                            {this.props.teacherBaseProps ?
                                <div className="mt-4">
                                    <div>
                                        <div className='flex flex-v-center w-100 mb-2 font-16'>
                                            {this.props.teacherBaseProps.teacher ?
                                                <Link to={'/teacher/pacing-guide/' + linkBack + '/' + this.props.teacherBaseProps.teacher.id}>
                                                    {linkBackText}
                                                </Link>
                                                : ''}
                                            {this.props.teacherBaseProps.admin ?
                                                <Link to={'/admin/pacing-guide/' + linkBack + '/' + this.props.teacherBaseProps.admin.id}>
                                                    {linkBackText}
                                                </Link>
                                                : ''}
                                            {this.props.teacherBaseProps.instructionalFacilitator ?
                                                <Link to={'/instructional-facilitator/pacing-guide/' + linkBack + '/' + this.props.teacherBaseProps.instructionalFacilitator.id}>
                                                    {linkBackText}
                                                </Link>
                                                : ''}
                                            {this.props.teacherBaseProps.schoolAdmin ?
                                                <Link to={'/school-admin/pacing-guide/' + linkBack + '/' + this.props.teacherBaseProps.schoolAdmin.id}>
                                                    {linkBackText}
                                                </Link>
                                                : ''}
                                            <Icon type='right' className='ml-1 mr-1 font-16' />
                                            <div>{this.state.standardName + ' - ' + this.state.standard}</div>

                                            <div className='flex flex-v-center ml-auto'>
                                                <div className='font-16 mr-1'>
                                                    View other standards:
                                                </div>
                                                {/*this.props.pacingGuideBaseProps &&
                                                    this.props.pacingGuideBaseProps.pacingGuide &&
                                                    this.props.pacingGuideBaseProps.pacingGuideHasData*/ false ?
                                                        <Select className='ml-auto' size='large' placeholder={'Select standard...'} value={
                                                            this.state.selectWeek && this.state.selectStandard && this.state.selectIdx ?
                                                                this.state.selectWeek + '--' + this.state.selectStandard + '--' + this.state.selectIdx :
                                                                undefined
                                                        }
                                                            onChange={this.onLessonPlanStandardChangeWeeks} style={{ width: '250px' }}>
                                                            {this.props.pacingGuideBaseProps &&
                                                                this.props.pacingGuideBaseProps.pacingGuide &&
                                                                this.props.pacingGuideBaseProps.weekNums &&
                                                                this.props.pacingGuideBaseProps.weekNums.map((weekNum, idx) => {
                                                                    if (weekNum === 0) {
                                                                        return false
                                                                    }
                                                                    let quarterAndWeek = getQuarterAndWeekFromNum(weekNum)
                                                                    const standards = this.props.pacingGuideBaseProps.pacingGuide.weeks[weekNum]
                                                                    return <Select.OptGroup key={idx} label={'Quarter: ' + quarterAndWeek.quarter + ', Week ' + quarterAndWeek.week}>
                                                                        {standards.map((standard, standardIdx) => {
                                                                            return <Select.Option key={weekNum + '--' + standardIdx} value={weekNum + '--' + standard.standard + '--' + standardIdx}>
                                                                                {standard.standard}
                                                                            </Select.Option>
                                                                        })}
                                                                    </Select.OptGroup>
                                                                })}
                                                        </Select>
                                                        :
                                                        <Select className='ml-auto' size='large' value={this.state.standard}
                                                            onChange={this.onLessonPlanStandardChange} style={{ width: '250px' }}>
                                                            {this.props.pacingGuideBaseProps &&
                                                                this.props.pacingGuideBaseProps.standards.map((standard, idx) => {
                                                                    return <Select.Option key={standard.standard} value={standard.standard}>{standard.standard}</Select.Option>
                                                                })}
                                                        </Select>}
                                            </div>
                                        </div>

                                        <div className='br-20 overflow-hidden mb-3 mt-2'>
                                            <div className='br-4 relative pl-3 pr-3 pt-3 relative'
                                                style={{ height: '220px', }}>

                                                <div className="absolute-tl w-100 h-100" style={{ backgroundColor: '#233142' }}>
                                                </div>
                                                <div className="absolute-tl w-100 h-100"
                                                >
                                                </div>
                                                <div className='absolute-tl w-100 h-100'>
                                                    <div className='h-100'>
                                                        <div className='flex flex-v-center font-40 font-fff w-100 h-100 p-4'>
                                                            <div className='w-100'>
                                                                <div className='font-40 font-bold font-fff'>{this.state.standardName}</div>
                                                                <div className='font-24 font-fff'>{'Standard: ' + this.state.standard}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {!this.state.lessonPlanFound ?
                                        <div style={{ width: '940px' }}>
                                            <div className='font-24 w-100'>
                                                This lesson plan is coming soon.
                                            </div>
                                        </div>
                                        : ''}
                                    {this.state.lessonPlan ?
                                        <div className='mt-4'>
                                            <Tabs tabPosition='left' size='large' defaultActiveKey="1" >
                                                <Tabs.TabPane tab="Standard" key="standard-tab">
                                                    <div className={'background-fff p-4 br-15 mb-100 ant-shadow ' + componentContainerClassName}>
                                                        <div className='flex flex-v-center w-100'>
                                                            {this.state.fileURLDict &&
                                                                this.state.fileURLDict.hasOwnProperty('lesson_plan_full') &&
                                                                this.state.fileURLDict['lesson_plan_full'] ?
                                                                <div className='mb-3'>
                                                                    <a
                                                                        href={this.state.fileURLDict['lesson_plan_full']}
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className={pdfButtonClassName}
                                                                    >
                                                                        <Icon type='file-pdf' className='font-20 mr-1' />
                                                                        <div>{'View Full PDF'}</div>
                                                                    </a>
                                                                </div>
                                                                : ''}

                                                        </div>
                                                        <div className='font-20'>
                                                            <h1 className='edviz-document-v2-heading-1'>Standard</h1>
                                                            <p><b>Subject:</b> {this.state.lessonPlan.standard.standardCategory}</p>
                                                            <p><b>Category:</b> {this.state.lessonPlan.standard.category}</p>
                                                            <p><b>Grade:</b> {this.state.lessonPlan.standard.grade}</p>
                                                            <p><b>Standard:</b> {this.state.lessonPlan.standard.standard}</p>
                                                            {this.state.lessonPlan &&
                                                                this.state.lessonPlan.standard &&
                                                                this.state.lessonPlan.standard.standard &&
                                                                this.props.pacingGuideBaseProps &&
                                                                this.props.pacingGuideBaseProps.standardsDict &&
                                                                this.props.pacingGuideBaseProps.standardsDict.hasOwnProperty(this.state.lessonPlan.standard.standard) &&
                                                                this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard] &&
                                                                this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard].hasOwnProperty('isPriority') &&
                                                                this.props.pacingGuideBaseProps.standardsDict[this.state.lessonPlan.standard.standard].isPriority ?
                                                                <p>*Priority Standard</p>
                                                                : ''}
                                                            <p>{this.state.lessonPlan.standard.title}</p>
                                                        </div>
                                                    </div>
                                                </Tabs.TabPane>

                                                {this.state.standard &&
                                                    this.state.lessonPlan &&
                                                    this.state.lessonPlan.hasOwnProperty('dokLevelDocs') &&
                                                    this.state.lessonPlan.dokLevelDocs &&
                                                    this.state.lessonPlan.dokLevelDocs.length > 0 &&
                                                    this.state.lessonPlan.hasOwnProperty('lessonPlanDocs') &&
                                                    this.state.lessonPlan.lessonPlanDocs &&
                                                    this.state.lessonPlan.lessonPlanDocs.length > 0 ?
                                                    <Tabs.TabPane tab="Sample Lesson" key="lesson-plan-tab">
                                                        {this.state.lessonPlan &&
                                                            this.state.lessonPlan.doc &&
                                                            this.state.lessonPlan.doc.components ?
                                                            <div className={'background-fff p-4 br-15 mb-100 ant-shadow ' + componentContainerClassName}>
                                                                {/*<LessonPlanPDFDownload
                                                                    className='mb-2'
                                                                    fileName={`Sample-Lesson-For-Standard-${this.state.standard}.pdf`}
                                                                    buttonText={'Download Sample Lesson PDF'}
                                                                    data={this.state.lessonPlan.printLessonPlanDocs}
                                                                />*/}
                                                                <DocumentDelegator
                                                                    document={{
                                                                        components: this.state.lessonPlan.lessonPlanDocs,
                                                                        metadata: { 'edvizVersion': '2' }
                                                                    }}
                                                                />
                                                            </div>
                                                            : ''}
                                                    </Tabs.TabPane>
                                                    : ''}

                                                {this.state.standard &&
                                                    this.state.lessonPlan &&
                                                    this.state.lessonPlan.hasOwnProperty('dokLevelDocs') &&
                                                    this.state.lessonPlan.dokLevelDocs &&
                                                    this.state.lessonPlan.dokLevelDocs.length > 0 &&
                                                    this.state.lessonPlan.hasOwnProperty('lessonPlanDocs') &&
                                                    this.state.lessonPlan.lessonPlanDocs &&
                                                    this.state.lessonPlan.lessonPlanDocs.length > 0 ?
                                                    <Tabs.TabPane tab="DOK Differentiation" key="dok-differentiation-tab">
                                                        {this.state.lessonPlan &&
                                                            this.state.lessonPlan.doc &&
                                                            this.state.lessonPlan.doc.components ?
                                                            <div className={'background-fff p-4 br-15 mb-100 ant-shadow ' + componentContainerClassName + ' edviz-list-style-none'}>
                                                                {/*<LessonPlanPDFDownload
                                                                    className='mb-2'
                                                                    fileName={`DOK-Differentiation-For-Standard-${this.state.standard}.pdf`}
                                                                    buttonText={'Download DOK Differentiation PDF'}
                                                                    data={this.state.lessonPlan.printDOKLevelDocs}
                                                                />*/}
                                                                <DocumentDelegator
                                                                    document={{
                                                                        components: this.state.lessonPlan.dokLevelDocs,
                                                                        metadata: { 'edvizVersion': '2' }
                                                                    }}
                                                                />
                                                            </div>
                                                            : ''}
                                                    </Tabs.TabPane>
                                                    : ''}

                                                {/* Dok level docs could not be parsed, display all components */}
                                                {!(this.state.lessonPlan &&
                                                    this.state.lessonPlan.hasOwnProperty('dokLevelDocs') &&
                                                    this.state.lessonPlan.dokLevelDocs &&
                                                    this.state.lessonPlan.dokLevelDocs.length > 0 &&
                                                    this.state.lessonPlan.hasOwnProperty('lessonPlanDocs') &&
                                                    this.state.lessonPlan.lessonPlanDocs &&
                                                    this.state.lessonPlan.lessonPlanDocs.length > 0) ?
                                                    <Tabs.TabPane tab="Sample Lesson" key="lesson-plan-tab">
                                                        {this.state.lessonPlan &&
                                                            this.state.lessonPlan.doc &&
                                                            this.state.lessonPlan.doc.components ?
                                                            <div className={'background-fff p-4 br-15 mb-100 ant-shadow ' + componentContainerClassName}>
                                                                <DocumentDelegator
                                                                    document={{
                                                                        components: this.state.lessonPlan.doc.components,
                                                                        metadata: { 'edvizVersion': '2' }
                                                                    }}
                                                                />
                                                            </div>
                                                            : ''}
                                                    </Tabs.TabPane>
                                                    : ''}


                                                {this.state.standard === 'ELA.3.AOR.5.1' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [graphicOrganizerEmpty_ela_3_aor_5_1,
                                                        graphicOrganizerFilled_ela_3_aor_5_1,
                                                        graphicOrganizerPrintable_ela_3_aor_5_1,
                                                        studentResource_ela_3_aor_5_1,
                                                        teacherResource_ela_3_aor_5_1].map((doc, idx) => {
                                                            let tabName = 'Graphic Organizer (Empty)'
                                                            if (idx === 1) {
                                                                tabName = 'Graphic Organizer (Filled)'
                                                            } else if (idx === 2) {
                                                                tabName = 'Printable Graphic Organizer'
                                                            } else if (idx === 3) {
                                                                tabName = 'Student Resource'
                                                            } else if (idx === 4) {
                                                                tabName = 'Teacher Resource'
                                                            }
                                                            return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                                <HTMLAssessmentViewer
                                                                    htmlContent={doc}
                                                                />
                                                            </Tabs.TabPane>
                                                        })
                                                    : ''}

                                                {this.state.standard === 'ELA.3.AOR.6.1.a' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [graphicOrganizerEmpty_ela_3_aor_6_1_a,
                                                        graphicOrganizerFilled_ela_3_aor_6_1_a,
                                                        graphicOrganizerPrintable_ela_3_aor_6_1_a,
                                                        studentResource_ela_3_aor_6_1_a,
                                                        teacherResource_ela_3_aor_6_1_a].map((doc, idx) => {
                                                            let tabName = 'Graphic Organizer (Empty)'
                                                            if (idx === 1) {
                                                                tabName = 'Graphic Organizer (Filled)'
                                                            } else if (idx === 2) {
                                                                tabName = 'Printable Graphic Organizer'
                                                            } else if (idx === 3) {
                                                                tabName = 'Student Resource'
                                                            } else if (idx === 4) {
                                                                tabName = 'Teacher Resource'
                                                            }
                                                            return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                                <HTMLAssessmentViewer
                                                                    htmlContent={doc}
                                                                />
                                                            </Tabs.TabPane>
                                                        })
                                                    : ''}

                                                {this.state.standard === 'ELA.3.AOR.8.1.a' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [graphicOrganizerEmpty_ela_3_aor_8_1_a,
                                                        graphicOrganizerFilled_ela_3_aor_8_1_a,
                                                        graphicOrganizerPrintable_ela_3_aor_8_1_a,
                                                        studentResource_ela_3_aor_8_1_a,
                                                        teacherResource_ela_3_aor_8_1_a].map((doc, idx) => {
                                                            let tabName = 'Graphic Organizer (Empty)'
                                                            if (idx === 1) {
                                                                tabName = 'Graphic Organizer (Filled)'
                                                            } else if (idx === 2) {
                                                                tabName = 'Printable Graphic Organizer'
                                                            } else if (idx === 3) {
                                                                tabName = 'Student Resource'
                                                            } else if (idx === 4) {
                                                                tabName = 'Teacher Resource'
                                                            }
                                                            return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                                <HTMLAssessmentViewer
                                                                    htmlContent={doc}
                                                                />
                                                            </Tabs.TabPane>
                                                        })
                                                    : ''}


                                                {this.state.standard === 'ELA.K.F.3.8.c' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [graphicOrganizerEmpty_ela_k_f_3_8_c,
                                                        graphicOrganizerFilled_ela_k_f_3_8_c,
                                                        graphicOrganizerPrintable_ela_k_f_3_8_c,
                                                        studentResource_ela_k_f_3_8_c,
                                                        teacherResource_ela_k_f_3_8_c,
                                                    ].map((doc, idx) => {
                                                        let tabName = 'Graphic Organizer (Empty)'
                                                        if (idx === 1) {
                                                            tabName = 'Graphic Organizer (Filled)'
                                                        } else if (idx === 2) {
                                                            tabName = 'Printable Graphic Organizer'
                                                        } else if (idx === 3) {
                                                            tabName = 'Student Resource'
                                                        } else if (idx === 4) {
                                                            tabName = 'Teacher Resource'
                                                        }
                                                        return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                            <HTMLAssessmentViewer
                                                                htmlContent={doc}
                                                            />
                                                        </Tabs.TabPane>
                                                    })
                                                    : ''}

                                                {this.state.standard === 'ELA.1.F.3.4.a' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [graphicOrganizerEmpty_ela_1_f_3_4_a,
                                                        graphicOrganizerFilled_ela_1_f_3_4_a,
                                                        graphicOrganizerPrintable_ela_1_f_3_4_a,
                                                        studentResource_ela_1_f_3_4_a,
                                                        teacherResource_ela_1_f_3_4_a,
                                                    ].map((doc, idx) => {
                                                        let tabName = 'Graphic Organizer (Empty)'
                                                        if (idx === 1) {
                                                            tabName = 'Graphic Organizer (Filled)'
                                                        } else if (idx === 2) {
                                                            tabName = 'Printable Graphic Organizer'
                                                        } else if (idx === 3) {
                                                            tabName = 'Student Resource'
                                                        } else if (idx === 4) {
                                                            tabName = 'Teacher Resource'
                                                        }
                                                        return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                            <HTMLAssessmentViewer
                                                                htmlContent={doc}
                                                            />
                                                        </Tabs.TabPane>
                                                    })
                                                    : ''}

                                                {this.state.standard === 'ELA.1.F.3.7' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [graphicOrganizerEmpty_ela_1_f_3_7,
                                                        graphicOrganizerFilled_ela_1_f_3_7,
                                                        graphicOrganizerPrintable_ela_1_f_3_7,
                                                        studentResource_ela_1_f_3_7,
                                                        teacherResource_ela_1_f_3_7,
                                                    ].map((doc, idx) => {
                                                        let tabName = 'Graphic Organizer (Empty)'
                                                        if (idx === 1) {
                                                            tabName = 'Graphic Organizer (Filled)'
                                                        } else if (idx === 2) {
                                                            tabName = 'Printable Graphic Organizer'
                                                        } else if (idx === 3) {
                                                            tabName = 'Student Resource'
                                                        } else if (idx === 4) {
                                                            tabName = 'Teacher Resource'
                                                        }
                                                        return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                            <HTMLAssessmentViewer
                                                                htmlContent={doc}
                                                            />
                                                        </Tabs.TabPane>
                                                    })
                                                    : ''}

                                                {this.state.standard === 'ELA.2.F.3.8.g' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [graphicOrganizerEmpty_ela_2_f_3_8_g,
                                                        graphicOrganizerFilled_ela_2_f_3_8_g,
                                                        graphicOrganizerPrintable_ela_2_f_3_8_g,
                                                        studentResource_ela_2_f_3_8_g,
                                                        teacherResource_ela_2_f_3_8_g,
                                                    ].map((doc, idx) => {
                                                        let tabName = 'Graphic Organizer (Empty)'
                                                        if (idx === 1) {
                                                            tabName = 'Graphic Organizer (Filled)'
                                                        } else if (idx === 2) {
                                                            tabName = 'Printable Graphic Organizer'
                                                        } else if (idx === 3) {
                                                            tabName = 'Student Resource'
                                                        } else if (idx === 4) {
                                                            tabName = 'Teacher Resource'
                                                        }
                                                        return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                            <HTMLAssessmentViewer
                                                                htmlContent={doc}
                                                            />
                                                        </Tabs.TabPane>
                                                    })
                                                    : ''}

                                                {this.state.standard === 'ELA.2.F.3.7' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [graphicOrganizerEmpty_ela_2_f_3_7,
                                                        graphicOrganizerFilled_ela_2_f_3_7,
                                                        graphicOrganizerPrintable_ela_2_f_3_7,
                                                        studentResource_ela_2_f_3_7,
                                                        teacherResource_ela_2_f_3_7,
                                                    ].map((doc, idx) => {
                                                        let tabName = 'Graphic Organizer (Empty)'
                                                        if (idx === 1) {
                                                            tabName = 'Graphic Organizer (Filled)'
                                                        } else if (idx === 2) {
                                                            tabName = 'Printable Graphic Organizer'
                                                        } else if (idx === 3) {
                                                            tabName = 'Student Resource'
                                                        } else if (idx === 4) {
                                                            tabName = 'Teacher Resource'
                                                        }
                                                        return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                            <HTMLAssessmentViewer
                                                                htmlContent={doc}
                                                            />
                                                        </Tabs.TabPane>
                                                    })
                                                    : ''}

                                                {this.state.standard === '8.F.4.d' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [
                                                        graphicOrganizerEmpty_math_8_8_F_4_d,
                                                        graphicOrganizerFilled_math_8_8_F_4_d,
                                                        graphicOrganizerPrintable_math_8_8_F_4_d,
                                                        studentResource_math_8_8_F_4_d,
                                                        teacherResource_math_8_8_F_4_d,
                                                    ].map((doc, idx) => {
                                                        let tabName = 'Graphic Organizer (Empty)'
                                                        if (idx === 1) {
                                                            tabName = 'Graphic Organizer (Filled)'
                                                        } else if (idx === 2) {
                                                            tabName = 'Printable Graphic Organizer'
                                                        } else if (idx === 3) {
                                                            tabName = 'Student Resource'
                                                        } else if (idx === 4) {
                                                            tabName = 'Teacher Resource'
                                                        }
                                                        return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                            <HTMLAssessmentViewer
                                                                htmlContent={doc}
                                                            />
                                                        </Tabs.TabPane>
                                                    })
                                                    : ''}

                                                {this.state.standard === 'A1.FLQE.2*' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [
                                                        graphicOrganizerEmpty_math_algebra_1_A1_FLQE_2_,
                                                        graphicOrganizerFilled_math_algebra_1_A1_FLQE_2_,
                                                        graphicOrganizerPrintable_math_algebra_1_A1_FLQE_2_,
                                                        studentResource_math_algebra_1_A1_FLQE_2_,
                                                        teacherResource_math_algebra_1_A1_FLQE_2_,
                                                    ].map((doc, idx) => {
                                                        let tabName = 'Graphic Organizer (Empty)'
                                                        if (idx === 1) {
                                                            tabName = 'Graphic Organizer (Filled)'
                                                        } else if (idx === 2) {
                                                            tabName = 'Printable Graphic Organizer'
                                                        } else if (idx === 3) {
                                                            tabName = 'Student Resource'
                                                        } else if (idx === 4) {
                                                            tabName = 'Teacher Resource'
                                                        }
                                                        return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                            <HTMLAssessmentViewer
                                                                htmlContent={doc}
                                                            />
                                                        </Tabs.TabPane>
                                                    })
                                                    : ''}



                                                {this.state.standard === 'ELA.E1.AOR.1.1' &&
                                                    this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    (this.props.teacherBaseProps.district.id === '64bec570267c8bfeea81f9ea' ||
                                                        this.props.teacherBaseProps.district.id === '521ce2f24da7037445000552') ?
                                                    [
                                                        graphicOrganizerEmpty_ELA_english_1_ELA_E1_AOR_1_1,
                                                        graphicOrganizerFilled_ELA_english_1_ELA_E1_AOR_1_1,
                                                        graphicOrganizerPrintable_ELA_english_1_ELA_E1_AOR_1_1,
                                                        studentResource_ELA_english_1_ELA_E1_AOR_1_1,
                                                        teacherResource_ELA_english_1_ELA_E1_AOR_1_1,
                                                    ].map((doc, idx) => {
                                                        let tabName = 'Graphic Organizer (Empty)'
                                                        if (idx === 1) {
                                                            tabName = 'Graphic Organizer (Filled)'
                                                        } else if (idx === 2) {
                                                            tabName = 'Printable Graphic Organizer'
                                                        } else if (idx === 3) {
                                                            tabName = 'Student Resource'
                                                        } else if (idx === 4) {
                                                            tabName = 'Teacher Resource'
                                                        }
                                                        return <Tabs.TabPane tab={tabName} key={'graphic-organizer-' + idx}>
                                                            <HTMLAssessmentViewer
                                                                htmlContent={doc}
                                                            />
                                                        </Tabs.TabPane>
                                                    })
                                                    : ''}



                                                <Tabs.TabPane tab="District Resources" key="district-resources-tab">
                                                    <div className={'background-fff p-4 br-15 mb-100 ant-shadow ' + componentContainerClassName}>
                                                        {this.props.teacherBaseProps &&
                                                            this.props.teacherBaseProps.district &&
                                                            this.state.standard &&
                                                            this.state.category &&
                                                            person ?
                                                            <PacingGuideUploads
                                                                districtId={this.props.teacherBaseProps.district.id}
                                                                standard={this.state.standard}
                                                                standardCategory={this.state.category}
                                                                personId={person.id}
                                                            /> : ''}
                                                    </div>
                                                </Tabs.TabPane>
                                                {this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.district &&
                                                    this.state.quizzes.length > 0 &&
                                                    this.state.standardName !== '' &&
                                                    this.state.standardName !== 'Kindergarten' &&
                                                    this.state.standardName !== '1st Grade' ?
                                                    <Tabs.TabPane tab="Quiz" key="quiz-tab">
                                                        <div className={'background-fff p-4 br-15 mb-100 ant-shadow ' + componentContainerClassName}>
                                                            <div className='flex flex-v-center w-100'>
                                                                <div className='edviz-document-v2-heading-1'>Quizzes</div>
                                                            </div>

                                                            <div>
                                                                <Tabs defaultActiveKey="quiz-0" animated={false}>
                                                                    {this.state.quizzes.map((assessment, idx) => {
                                                                        let disableQuiz = false
                                                                        // this.state.lessonPlan.standard.standardCategory === 'Math' ||
                                                                        if (this.state.lessonPlan &&
                                                                            (this.state.disabledQuizzesDict.hasOwnProperty(this.state.lessonPlan.standard.standard) ||
                                                                                this.state.lessonPlan.standard.standardName === 'Kindergarten' ||
                                                                                this.state.lessonPlan.standard.standardName === '1st Grade')) {
                                                                            disableQuiz = true
                                                                        }
                                                                        let isKindergartenOrFirstGrade = false
                                                                        if (this.state.lessonPlan &&
                                                                            (this.state.lessonPlan.standard.standardName === 'Kindergarten' ||
                                                                                this.state.lessonPlan.standard.standardName === '1st Grade')) {
                                                                            isKindergartenOrFirstGrade = true
                                                                        }
                                                                        return <Tabs.TabPane tab={<div className='font-20'>{'Quiz #' + (idx + 1)}</div>} key={'quiz-' + idx} className='font-black'>
                                                                            <div className='pt-1'>
                                                                                {this.state.lessonPlan &&
                                                                                    this.state.disabledQuizzesDict.hasOwnProperty(this.state.lessonPlan.standard.standard) ?
                                                                                    <div className='font-20 mb-05'>This quiz is currently under development and will be available soon.</div>
                                                                                    : ''}

                                                                                <div className='flex flex-v-center w-100 mt-1'>
                                                                                    {this.props.teacherBaseProps &&
                                                                                        this.props.teacherBaseProps.teacher ?
                                                                                        <PacingGuideAssessmentModalForm
                                                                                            assessment={assessment}
                                                                                            teacherBaseProps={this.props.teacherBaseProps}
                                                                                            disableQuiz={disableQuiz}
                                                                                        />
                                                                                        : ''}

                                                                                    {this.props.teacherBaseProps &&
                                                                                        this.props.teacherBaseProps.teacher &&
                                                                                        (this.props.teacherBaseProps.teacher.id === '64e638af44f2aa047457f924' ||
                                                                                            this.props.teacherBaseProps.teacher.id === '64c9bd88ee3bd703dc3f33ca') ?
                                                                                        <div className='flex flex-v-center w-100'>


                                                                                            {/*<Popconfirm
                                                                                                title="Are you sure you want to delete this quiz?"
                                                                                                onConfirm={() => {
                                                                                                    this.deleteQuiz(assessment);
                                                                                                }}
                                                                                                okText="Yes"
                                                                                                cancelText="No"
                                                                                            >
                                                                                                <Button type='danger' className='ml-1 lg-btn'>Delete Quiz</Button>
                                                                                            </Popconfirm>*/}

                                                                                            <Checkbox
                                                                                                key={'quiz-live-' + assessment.id + '-' + assessment.live + '-' + idx}
                                                                                                className='ml-auto'
                                                                                                checked={assessment.live}
                                                                                                onChange={(e) => {
                                                                                                    this.setLive(assessment, e.target.checked)
                                                                                                }}
                                                                                            >
                                                                                                <span className='pl-1'>Live</span>
                                                                                            </Checkbox>
                                                                                        </div> : ''}
                                                                                </div>

                                                                                {/*<div className='mt-3 mb-1 font-24 font-bold'>
                                                                                    {assessment.assessmentTitle}
                                                                                    </div>*/}
                                                                                {assessment.docs && assessment.docs.map((lessonPlanItem, idx) => {
                                                                                    return <div className={'font-black font-20 br-4 mb-50' + (idx === 0 ? ' mt-2' : '')}
                                                                                        key={'assessment-idx-' + idx}>
                                                                                        <div className='font-24'>Question {idx + 1}</div>

                                                                                        <DocumentDelegator
                                                                                            document={lessonPlanItem}
                                                                                        />
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                        </Tabs.TabPane>
                                                                    })}
                                                                </Tabs>
                                                            </div>

                                                        </div>
                                                    </Tabs.TabPane> : ''}

                                                {this.props.teacherBaseProps &&
                                                    this.props.teacherBaseProps.teacher &&
                                                    (this.props.teacherBaseProps.teacher.id === '64e638af44f2aa047457f924' ||
                                                        this.props.teacherBaseProps.teacher.id === '64c9bd88ee3bd703dc3f33ca') &&
                                                    this.state.notLiveQuizzes.length > 0 &&
                                                    this.state.standardName !== '' &&
                                                    this.state.standardName !== 'Kindergarten' &&
                                                    this.state.standardName !== '1st Grade' ?
                                                    <Tabs.TabPane tab="Not Live Quiz" key="not-live-quiz-tab">
                                                        <div className={componentContainerClassName}>
                                                            <div className='mb-2 flex flex-v-center w-100'>
                                                                <div className='edviz-document-v2-heading-1'>Quizzes that are not live</div>
                                                            </div>

                                                            <div>

                                                                <Tabs defaultActiveKey="quiz-not-live-0" animated={false}>
                                                                    {this.state.notLiveQuizzes.map((assessment, idx) => {
                                                                        let disableQuiz = false
                                                                        // this.state.lessonPlan.standard.standardCategory === 'Math' ||
                                                                        if (this.state.lessonPlan &&
                                                                            (this.state.disabledQuizzesDict.hasOwnProperty(this.state.lessonPlan.standard.standard) ||
                                                                                this.state.lessonPlan.standard.standardName === 'Kindergarten' ||
                                                                                this.state.lessonPlan.standard.standardName === '1st Grade')) {
                                                                            disableQuiz = true
                                                                        }
                                                                        let isKindergartenOrFirstGrade = false
                                                                        if (this.state.lessonPlan &&
                                                                            (this.state.lessonPlan.standard.standardName === 'Kindergarten' ||
                                                                                this.state.lessonPlan.standard.standardName === '1st Grade')) {
                                                                            isKindergartenOrFirstGrade = true
                                                                        }
                                                                        return <Tabs.TabPane tab={<div className='font-20'>{'Quiz #' + (idx + 1)}</div>} key={'quiz-not-live-' + idx} className='font-black' animated={false}>
                                                                            <div className='pt-2'>
                                                                                {this.state.lessonPlan &&
                                                                                    this.state.disabledQuizzesDict.hasOwnProperty(this.state.lessonPlan.standard.standard) ?
                                                                                    <div className='font-20 mb-05'>This quiz is currently under development and will be available soon.</div>
                                                                                    : ''}

                                                                                <div className='flex flex-v-center w-100 mt-2'>
                                                                                    {this.props.teacherBaseProps &&
                                                                                        this.props.teacherBaseProps.teacher ?
                                                                                        <PacingGuideAssessmentModalForm
                                                                                            assessment={assessment}
                                                                                            teacherBaseProps={this.props.teacherBaseProps}
                                                                                            disableQuiz={disableQuiz}
                                                                                        />
                                                                                        : ''}




                                                                                    <Popconfirm
                                                                                        title="Are you sure you want to delete this quiz?"
                                                                                        onConfirm={() => {
                                                                                            this.deleteQuiz(assessment);
                                                                                        }}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <Button type='danger' className='ml-1 lg-btn'>Delete Quiz</Button>
                                                                                    </Popconfirm>

                                                                                    <Checkbox
                                                                                        className='ml-auto'
                                                                                        key={'quiz-not-live-' + assessment.id + '-' + assessment.live + '-' + idx}
                                                                                        checked={assessment.live}
                                                                                        onChange={(e) => {
                                                                                            this.setLive(assessment, e.target.checked)
                                                                                        }}
                                                                                    >
                                                                                        <span className='pl-1'>Live</span>
                                                                                    </Checkbox>
                                                                                </div>
                                                                                {!isKindergartenOrFirstGrade && assessment.docs && assessment.docs.map((lessonPlanItem, idx) => {
                                                                                    return <div className={'font-black font-20 border ant-shadow br-4 p-3 mb-50' + (idx === 0 ? ' mt-2' : '')}
                                                                                        key={'assessment-idx-' + idx}>
                                                                                        <div className='font-24'>Question {idx + 1}</div>

                                                                                        <DocumentDelegator
                                                                                            document={lessonPlanItem}
                                                                                        />
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                        </Tabs.TabPane>
                                                                    })}
                                                                </Tabs>
                                                            </div>

                                                        </div>
                                                    </Tabs.TabPane> : ''}
                                            </Tabs>
                                        </div> : ''}
                                </div> : ''}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default PacingGuideLessonPlanV3
